import codeBg from "./codeBg.jpg"
import keyboardAndMouse from "./keyboardAndMouse.jpg"
import lines from "./lines.jpg"
import lines2 from "./lines2.jpg"
import business from "./business.jpg"
import dresses from "./dresses.jpg"

const splashPhotos = [
  codeBg,
  lines,
  keyboardAndMouse,
  lines2,
  business,
  dresses,
]

export default splashPhotos
