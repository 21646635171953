import React from "react"

const Logo = (color) => (
  <svg x="0px" y="0px" viewBox="0 0 72 72">
    <path
      fill={color}
      d="M0,0v72h72V0H0z M6.487,61.373l9.737-49.112H2.754l1.648-9.136h36.421l-1.647,9.136H25.707l-7.988,49.112
      H6.487z M62.288,68.875h-8.84l4.454-30.109l1.802-12.019l-4.037,8.147l-9.178,18.13h-6.275l-2.065-18.13l-0.771-8.147l-2.41,11.773
      l-6.374,30.354h-8.514l12.215-53.25H43.62l1.959,15.116l0.707,8.515l3.686-8.027l7.744-15.604h11.53L62.288,68.875z"
    />
  </svg>
)

export default Logo
